import React from "react";

import { Container, Typography, Link } from '@mui/material';

export default function Error(prop) {
    return (
        <Container maxWidth="sm">
            <Typography variant="h2">{prop.errorStatus}</Typography>
            <Typography variant="body1">
                {prop.errorMessage}
            </Typography>
        </Container>
    );
}