import React, { useRef, useState, useEffect } from "react";
import { navigate } from "gatsby";
import PropTypes from 'prop-types';
import { useQueryParam, StringParam, NumberParam } from "use-query-params";

import { Container, Box, Button, TextField, FormControl, Select, InputLabel, InputAdornment, LinearProgress, Typography, Grid, CircularProgress } from '@mui/material';
import { ErrorOutlineRounded, CheckCircleOutlineRounded } from '@mui/icons-material';

import Layout from "../components/layout"
import Seo from "../components/seo"
import ShowErrorMessage from "../components/showErrorMessage"
import FormHeader from "../components/formHeader"
import SendButton from "../components/sendButton"
import Error from "../components/error"
import ErrorDialog from "../components/errorDialog"

import { getAuth, signInAnonymously } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";

export default function CommonContents() {
    return (
        <Layout>
            <Seo title="免除申請" robotsType="noindex" />
            <ShowContents />
        </Layout>
    );
}

function ShowContents() {
    const [uuidQuery] = useQueryParam("uuid", StringParam);
    const [timeQuery] = useQueryParam("timestamp", NumberParam);
    const lapse = Date.now() - timeQuery;

    const [checkUuidError, setCheckUuidError] = useState(null);
    const [uuidHavechecked, setUuidHaveChecked] = useState(false);

    const [apiState, setApiState] = useState({
        existance: false,
        feeExemptionApplied: false,
        registered: false
    });

    const myInit = {
        method: 'POST',
        mode: "cors",
        redirect: "follow",
        body: JSON.stringify({
            uuid: uuidQuery,
        })
    };
    console.log(myInit);
    useEffect(() => {
        fetch(`https://script.google.com/macros/s/${process.env.GATSBY_CHECK_UUID_API_DEPLOY_ID}/exec`, myInit)
            // .then((res) => { console.log(res); })
            .then(res => res.json())
            .then(
                (result) => {
                    setApiState(({ ...apiState, existance: result.existance, feeExemptionApplied: result.feeExemptionApplied, registered: result.registered }));
                    console.log(result);
                    setUuidHaveChecked(true);
                },
                (error) => {
                    setUuidHaveChecked(true);
                    setCheckUuidError(error);
                    console.log("error");
                }
            )
    }, [])

    if (uuidHavechecked === false) {
        return (<CircularProgress />);
    } else if (lapse > 86400000) { // 86400000ms=24h
        return (<Error errorStatus="タイムアウトエラー" errorMessage="URLはタイムアウトしています。初めからやり直してください。" />);
    } else if (checkUuidError !== null) {
        console.log(checkUuidError);
        return (<Error errorStatus="読み込みエラー" errorMessage="ページの読み込みに失敗しました。リロードしてください。それでも解決しない場合には、初めからやり直してください。" />);
    } else if (uuidQuery === undefined || apiState.uuidExistance === false) {
        return (<Error errorStatus="URL エラー" errorMessage="URL が間違っています。認証に使用したメールアドレスにお送りした URL をご確認ください。それでも解決しない場合には、初めからやり直してください。" />);
    } else if (apiState.feeExemptionApplied === true && apiState.registered === true) {
        return (<Error errorStatus="回答済み" errorMessage="ご使用のメールアドレスでは既に回答済みです。審査終了まで今しばらくお待ちください。" />);
    } else {
        return (<Registaration uuidQuery={uuidQuery} />);
    }
}

function Registaration(props) {

    const [sendingError, setSendingError] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [inputError, setInputError] = useState({
        enrolledSchool: true,
        schoolType: true,
        graduationYear: true,
        birthday: true,
        studentId: true,
    });

    const [haveEdited, setHaveEdited] = useState({
        enrolledSchool: false,
        schoolType: false,
        graduationYear: false,
        birthday: false,
        studentId: false,
    });

    const [inputData, setInputData] = useState({
        uuid: props.uuidQuery,
        enrolledSchool: "",
        schoolType: "",
        graduationYear: "",
        birthday: "",
        studentId: "",
    });

    const entrykey = {
        uuid: "entry.950856551",
        enrolledSchool: "entry.1938895071",
        schoolType: "entry.791321812",
        graduationYear: "entry.690517698",
        birthday: "entry.562795703",
        studentId: "entry.1029858498",
    }

    const closeSendingError = () => {
        setSendingError(false);
    }

    // for uploading file
    const inputStudentIdRef = useRef(null);
    const [fileName, setFileName] = useState("");
    const [fileData, setFileData] = useState();
    const [fileUploadable, setFileUplodable] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(-1);
    const [uploadingState, setUploadingState] = useState("");
    const [query] = useQueryParam("uuid", StringParam);

    const schoolTypeOptions = ["中学校", "高等学校", "中等教育学校", "高等専門学校", "大学（学部）", "大学院（修士課程）", "大学院（博士課程）", "専門学校", "その他"];

    const ValidationIcon = (inputErrorName) => {
        if (!inputError?.[inputErrorName.inputErrorName] && haveEdited?.[inputErrorName.inputErrorName]) {
            return (<InputAdornment position="end"><CheckCircleOutlineRounded color="success" /></InputAdornment>);
        } else if (haveEdited?.[inputErrorName.inputErrorName]) {
            return (<InputAdornment position="end"><ErrorOutlineRounded color="error" /></InputAdornment>);
        } else {
            return (<></>);
        }
    }

    const changeHaveEditedToTrue = () => {
        setHaveEdited(({ ...haveEdited, enrolledSchool: true, schoolType: true, graduationYear: true, birthday: true, studentId: true }));
    }

    const handleChackChange = (name, event, pattern) => {
        setInputData({ ...inputData, [name]: event.target.value });
        setHaveEdited(({ ...haveEdited, [name]: true }));
        var constraints = {
            [name]: [pattern]
        }
        var constraint = new RegExp(constraints[name][0]);
        if (constraint.test(event.target.value)) {
            setInputError({ ...inputError, [name]: false });
        } else {
            setInputError({ ...inputError, [name]: true });
        }
    };

    const onFileInputChange = (event) => {
        const inputFile = event.target.files[0];
        if (!inputFile) return;
        const fileSizeLimit = 10; //MB
        if (inputFile.size > 1024 * 1024 * fileSizeLimit) {
            // ファイルサイズが制限以上
            setUploadingProgress(-2);
            setUploadingState(`ファイルサイズは ${fileSizeLimit} MB 以下にしてください。`); // エラーメッセージを表示
            setFileData("");
            setFileUplodable(false);
            setFileName(null);
            return; // この時点で処理を終了する
        } else {
            setUploadingProgress(-1);
            setFileUplodable(true);
            setUploadingState("Please press upload");
            setFileName(inputFile.name);
            setFileData(inputFile);
            console.log(inputFile);
        }
    };

    const chooseFile = () => {
        console.log(inputStudentIdRef.current);
        inputStudentIdRef.current.click();
    };

    const anonymouslyAuth = () => {
        const auth = getAuth();
        signInAnonymously(auth)
            .then(() => {
                // Signed in..
                console.log("success: sign in");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setUploadingProgress(-2);
                setUploadingState(`error: ${errorCode} ${errorMessage}`);
                setFileUplodable(true);
                console.log(error);
            });
    };

    const handleFileUpload = async (event) => {
        event.preventDefault();
        if (!fileUploadable) return;

        setFileUplodable(false);
        console.log(fileUploadable);

        const file = fileData;
        if (!file) return;

        try {
            await anonymouslyAuth(); // sign in

            const storage = await getStorage();  // Create a root reference
            const uplodeDate = Date.now();

            const fileExtension = file.name.split('.').pop();
            const uplodeFileName = `${query}_${uplodeDate}.${fileExtension}`;
            setInputData({ ...inputData, studentId: uplodeFileName });
            const studentIdImagesRef = await ref(storage, `/images/registrationForm/studentID/${uplodeFileName}`);  // Create a reference to 'images/mountains.jpg'

            // 'file' comes from the Blob or File API
            const metadata = {
                contentType: `${file.type}`,
            };

            const uploadTask = uploadBytesResumable(studentIdImagesRef, file, metadata);

            await uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadingProgress(progress);
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            setUploadingState('Upload is paused');
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                    setUploadingProgress(-2);
                    setUploadingState("error: uploading is failed");
                    setFileUplodable(true);
                    console.log(error);
                },
                () => {
                    // Handle successful uploads on complete
                    setUploadingState("アップロード完了");
                    setInputError(({ ...inputError, studentId: false }));
                }
            );

        } catch (error) {
            window.alert(error);
            setFileUplodable(true);
        }
    }

    const checkSubmission = async (event) => {
        setButtonDisabled(true);
        changeHaveEditedToTrue();
        const submitter = event.nativeEvent.submitter;
        event.preventDefault();
        if (submitter === null) {
            setButtonDisabled(false);
            return false;
        } else if (submitter.id !== "sendButton") {
            setButtonDisabled(false);
            return false;
        } else {
            let formData = "";
            const entry = Object.keys(entrykey);
            for (let index = 0; index < entry.length; index++) {
                formData += `${entrykey?.[entry[index]]}=${inputData?.[entry[index]]}`;
                if (index < entry.length - 1) {
                    formData += "&";
                }
            }
            const myInit = {
                method: 'POST',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8",
                },
                redirect: 'follow',
                body: encodeURI(formData)
            };
            await fetch(`https://secure-headland-84331.herokuapp.com/https://docs.google.com/forms/d/e/1FAIpQLSfruw3U3paoVVTGyrektdJcovf5T2PFDcO5V1siusbwvIVsmg/formResponse`, myInit)
                .then(response => {
                    const result = response;
                    console.log(result.status);
                    if (!result.ok) {
                        console.error('サーバーエラー');
                    } else {
                        navigate("/submitted", { state: { title: "免除申請", message: "免除申請が完了しました。確認のメールをお送りいたしましたので、ご確認ください。もしメールが半日以内に届かない場合には、contact@techfeelo.orgまでご連絡ください。" } })
                    }
                })
                .catch(() => {
                    console.log('エラー');
                    setSendingError(true);
                    setButtonDisabled(false);
                })
        }
    }

    return (
        <>
            <FormHeader currentStep={3} />
            <Container component="main" maxWidth="sm" style={{ marginTop: "3%", marginBottom: "5%" }}>
                <Box
                    component="form"
                    onSubmit={(event) => { checkSubmission(event); }}
                >
                    <Typography variant="h3">会費免除申請</Typography>
                    <Typography variant="body2">
                        一般社団法人techfeeloでは、入会の申込み日時点で、30歳以下の学生・生徒及びこれらに準じると当法人事務局が判断した正会員（個人）に対し、入会金、年会費ともに半額を免除しています。
                        （一般社団法人techfeelo 会員規約第6条第4項の2以外の事由での免除を申請する場合には、このフォームでの申込みを行う前に contact@techfeelo.org までお問い合わせください。）
                    </Typography>
                    <TextField
                        margin="normal" variant="outlined" fullWidth autoFocus={true}
                        label="在籍校" id="enrolledSchool" required
                        helperText="正式名称でご記入ください。"
                        onChange={e => {
                            handleChackChange("enrolledSchool", e, "^[^(\\^　!\"#$%&\'()\\*\\+\\-\\.,\\/:;<=>?@\\[\\]\\\^_`{|}~！”＃＄％＆’（）ー＝＾～￥｜＠‘「｛」｝：＊；＋、＜。＞・？￥＿)]+$");
                        }}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="enrolledSchool" />
                            )
                        }}
                        error={inputError.enrolledSchool && haveEdited.enrolledSchool}
                    />

                    <FormControl fullWidth>
                        <InputLabel id="schoolType" error={inputError.schoolType && haveEdited.schoolType}>在籍校の種別</InputLabel>
                        <Select
                            native
                            required
                            margin="dense"
                            labelId="schoolType"
                            id="schoolType"
                            label="在籍校の種別"
                            defaultValue=""
                            onChange={e => {
                                setInputData({ ...inputData, schoolType: e.target.value });
                                setHaveEdited({ ...haveEdited, "schoolType": true });
                                if (e.target.value !== "") {
                                    setInputError({ ...inputError, "schoolType": false });
                                }
                            }}
                            error={inputError.schoolType && haveEdited.schoolType}
                        >
                            <option value={""} disabled></option>
                            {schoolTypeOptions.map((schoolTypeOptions) => (
                                <option value={schoolTypeOptions}>{schoolTypeOptions}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        margin="normal" variant="outlined" fullWidth type="month"
                        label="卒業予定年" id="graduationYear" required
                        helperText="YYYY-MM の型式でご入力ください。"
                        onChange={e => {
                            handleChackChange("graduationYear", e, "^[0-9]{4}-(0[1-9]|1[0-2])$");
                        }}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="graduationYear" />
                            )
                        }}
                        error={inputError.graduationYear && haveEdited.graduationYear}
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                        margin="normal" variant="outlined" fullWidth type="date"
                        label="生年月日" id="birthday" required
                        helperText="入会を希望する者が、入会の申込み日時点で30歳以下であること及び、卒業予定年月以前に30歳を超えないことの確認に用います。 YYYY-MM-DD の形式でご入力ください。"
                        onChange={e => {
                            handleChackChange("birthday", e, "^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$");
                        }}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="birthday" />
                            )
                        }}
                        error={inputError.birthday && haveEdited.birthday}
                        InputLabelProps={{ shrink: true }}
                    />

                    <Grid sx={{ p: 1 }} style={{ marginBottom: "3%" }} container spacing={0.8} alignItems="center">
                        <Typography variant="body1">在学証明書類等の写しをここにアップロードしてください。 *</Typography>
                        <Typography variant="body2">
                            学生証あるいは在学証明書の写し（写真可）をjpeg・pngのいずれかのファイル形式でアップロードしてください。<br />
                            入会を希望する者の年齢が中学校相当の学齢以下の場合には、年齢を確認できる書類（健康保険証等）の写しでも構いません。
                            ただし、年齢を確認できる書類にマイナンバーが記載されている場合にはマイナンバーの部分が見えないように加工してください。<br />
                            アップロードされたファイルは、免除可否の判断を行う目的にのみ使用します。<br />
                            ※ ファイルサイズ制限: 10 MB 以下
                        </Typography>
                        <Grid item xs={8} sm={4}>
                            <Button variant="outlined" onClick={chooseFile} fullWidth>
                                ファイルを選択
                                <input
                                    hidden
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    id="studentid"
                                    onChange={onFileInputChange}
                                    ref={inputStudentIdRef}
                                    value=""
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body2">{fileName}</Typography>
                        </Grid>

                        <Grid item xs={8} sm={4}>
                            <Button
                                variant="outlined"
                                onClick={(event) => {
                                    handleFileUpload(event);
                                }}
                                disabled={!fileUploadable}
                                fullWidth
                            >
                                アップロード
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {(uploadingProgress !== -2 && uploadingProgress !== -1 && uploadingProgress !== 100) && <LinearProgressWithLabel variant="determinate" value={uploadingProgress} />}
                            {((uploadingProgress === -1) || uploadingProgress === 100) && <Typography variant="body2" color="text.secondary">{uploadingState}</Typography>}
                            {uploadingProgress === -2 && <Typography variant="body2" color="error">{uploadingState}</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <ShowErrorMessage error={haveEdited.studentId === true && inputError.studentId === true} errorMessage="ファイルをアップロードしてください。" />
                        </Grid>
                    </Grid>
                    <SendButton changeHaveEditedToTrue={changeHaveEditedToTrue} inputError={inputError} haveEdited={haveEdited} disabled={buttonDisabled} />
                </Box>
                <ErrorDialog open={sendingError} closeSendingError={closeSendingError} />
            </Container>
        </>
    );
}


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: "4em" }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};
