import React from 'react'

import { Typography, Stack } from '@mui/material';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';

const ShowErrorMessage = (prop) => {
    if (prop.error) {
        return (
            <Stack direction="row" spacing={2} alignItems="center">
                <ErrorOutlineRounded color="error" />
                <Typography variant="body1" color="error">
                    {prop.errorMessage}
                </Typography>
            </Stack>
        );
    } else {
        return (<></>);
    }
}

export default ShowErrorMessage